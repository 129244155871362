<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <card>
      <h5 slot="header" class="title">Edit Bet ( Bẻ Cầu ) tài khoản user</h5>
      <div class="col-md-8">
        <base-select
          :options="optionsArea"
          label="Chọn sàn"
          v-model="adminInput.areaName"
          name="item-areaName"
        >
        </base-select>
      </div>
      <div class="col-md-8">
        <base-input
          label="Biệt danh"
          v-model="adminInput.nickName"
          name="item-nickname"
        >
        </base-input>
      </div>
      <div class="col-md-8">
        <base-input
          label="Số dư"
          v-model="adminInput.balance"
          name="item-balance"
        >
        </base-input>
      </div>
      <div class="col-md-8">
        <base-input
          label="Số tiền vào lệnh"
          v-model="betAmount"
          name="item-amount"
        >
        </base-input>
      </div>
      <div class="col-md-8">
        <base-button @click="searchUserEdit()">Tra số dư</base-button>
        <base-button
          class="red"
          color="red"
          @click="BetBuySell('sell')"
          :disabled="this.turnPhase === 'WAITING'"
          >BÁN</base-button
        >
        <base-button
          class="green"
          color="green"
          @click="BetBuySell('buy')"
          :disabled="this.turnPhase === 'WAITING'"
          >MUA</base-button
        >
        <div class="timeCount" style="dislay: flex">
          <base-input
            :aria-readonly="true"
            label="Thời gian"
            v-model="this.countDown"
          ></base-input>
          <base-input
            :aria-readonly="true"
            label="Phiên"
            v-model="this.turnPhase"
          ></base-input>
        </div>
      </div>
    </card>
    <card>
      <div>
        <h4 style="color: green; font-size: 17px">
          Khối lượng Buy: ${{ volumeBuy }}
        </h4>
        <h4 style="color: red; font-size: 17px">
          Khối lượng Sell: ${{ volumeSell }}
        </h4>
        <h4>
          Bẻ cầu:
          <span>
            <span v-if="breakType == 'BUY'" class="green"><svg
                  width="20"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                >
                  <path
                    style="fill: #a5eb78"
                    d="M512,256.001C512,114.615,397.385,0,255.999,0S0,114.615,0,256.001
	C0,397.385,114.615,512,255.999,512S512,397.385,512,256.001z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M158.815,392.039l93.111-48.308c2.554-1.325,5.594-1.325,8.148,0l93.111,48.308
	c7.261,3.767,15.306-3.494,12.302-11.102L264.227,124.533c-2.947-7.462-13.508-7.462-16.456,0L146.513,380.937
	C143.509,388.545,151.555,395.806,158.815,392.039z"
                  />
                  <path
                    style="opacity: 0.1; enable-background: new"
                    d="M68.872,256.001c0-129.706,96.466-236.866,221.564-253.688
	C279.172,0.798,267.68,0,256.001,0C114.615,0,0,114.615,0,256.001S114.615,512,256.001,512c11.68,0,23.171-0.798,34.436-2.313
	C165.338,492.865,68.872,385.705,68.872,256.001z"
                  />
                </svg></span>
            <span v-if="breakType == 'SELL'" class="red">
              <svg width="20" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" transform="scale(1, -1) translate(0, 0)">
<path style="fill:#ff0000;" d="M512,256.001C512,114.615,397.385,0,255.999,0S0,114.615,0,256.001
	C0,397.385,114.615,512,255.999,512S512,397.385,512,256.001z"/>
<path style="fill:#FFFFFF;" d="M158.815,392.039l93.111-48.308c2.554-1.325,5.594-1.325,8.148,0l93.111,48.308
	c7.261,3.767,15.306-3.494,12.302-11.102L264.227,124.533c-2.947-7.462-13.508-7.462-16.456,0L146.513,380.937
	C143.509,388.545,151.555,395.806,158.815,392.039z"/>
<path style="opacity:0.1;enable-background:new    ;" d="M68.872,256.001c0-129.706,96.466-236.866,221.564-253.688
	C279.172,0.798,267.68,0,256.001,0C114.615,0,0,114.615,0,256.001S114.615,512,256.001,512c11.68,0,23.171-0.798,34.436-2.313
	C165.338,492.865,68.872,385.705,68.872,256.001z"/>
</svg></span>
            <span v-if="breakType == 'DRAW'">
<svg width="20px" height="20px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 2h10v2h8v14H11v-2H5v6H3V2zm2 12h8v2h6V6h-8V4H5v10z" fill="#000000"/>
                </svg></span>
            <span v-if="breakType == 'NONE'">

<svg fill="#fff" width="20px" height="20px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
  <path d="M128,20.00012a108,108,0,1,0,108,108A108.12217,108.12217,0,0,0,128,20.00012Zm0,192a84,84,0,1,1,84-84A84.0953,84.0953,0,0,1,128,212.00012Zm40.48535-107.51465L144.9707,128.00012l23.51465,23.51465a12.0001,12.0001,0,0,1-16.9707,16.9707L128,144.97082l-23.51465,23.51465a12.0001,12.0001,0,0,1-16.9707-16.9707l23.51465-23.51465L87.51465,104.48547a12.0001,12.0001,0,0,1,16.9707-16.9707L128,111.02942l23.51465-23.51465a12.0001,12.0001,0,0,1,16.9707,16.9707Z"/>
                </svg></span>
          </span>
        </h4>
        <base-button class="green" @click="SelectBetType('BUY')"
          ><svg
                  width="20"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                >
                  <path
                    style="fill: #a5eb78"
                    d="M512,256.001C512,114.615,397.385,0,255.999,0S0,114.615,0,256.001
	C0,397.385,114.615,512,255.999,512S512,397.385,512,256.001z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M158.815,392.039l93.111-48.308c2.554-1.325,5.594-1.325,8.148,0l93.111,48.308
	c7.261,3.767,15.306-3.494,12.302-11.102L264.227,124.533c-2.947-7.462-13.508-7.462-16.456,0L146.513,380.937
	C143.509,388.545,151.555,395.806,158.815,392.039z"
                  />
                  <path
                    style="opacity: 0.1; enable-background: new"
                    d="M68.872,256.001c0-129.706,96.466-236.866,221.564-253.688
	C279.172,0.798,267.68,0,256.001,0C114.615,0,0,114.615,0,256.001S114.615,512,256.001,512c11.68,0,23.171-0.798,34.436-2.313
	C165.338,492.865,68.872,385.705,68.872,256.001z"
                  />
                </svg></base-button
        >
        <base-button class="red" @click="SelectBetType('SELL')"
          ><svg width="20" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" transform="scale(1, -1) translate(0, 0)">
<path style="fill:#ff0000;" d="M512,256.001C512,114.615,397.385,0,255.999,0S0,114.615,0,256.001
	C0,397.385,114.615,512,255.999,512S512,397.385,512,256.001z"/>
<path style="fill:#FFFFFF;" d="M158.815,392.039l93.111-48.308c2.554-1.325,5.594-1.325,8.148,0l93.111,48.308
	c7.261,3.767,15.306-3.494,12.302-11.102L264.227,124.533c-2.947-7.462-13.508-7.462-16.456,0L146.513,380.937
	C143.509,388.545,151.555,395.806,158.815,392.039z"/>
<path style="opacity:0.1;enable-background:new    ;" d="M68.872,256.001c0-129.706,96.466-236.866,221.564-253.688
	C279.172,0.798,267.68,0,256.001,0C114.615,0,0,114.615,0,256.001S114.615,512,256.001,512c11.68,0,23.171-0.798,34.436-2.313
	C165.338,492.865,68.872,385.705,68.872,256.001z"/>
</svg>


                </base-button
        >
        <base-button @click="SelectBetType('DRAW')">

<svg width="20px" height="20px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 2h10v2h8v14H11v-2H5v6H3V2zm2 12h8v2h6V6h-8V4H5v10z" fill="#fff"/>
</svg></base-button>
        <base-button @click="SelectBetType('NONE')">

<svg fill="#fff" width="20px" height="20px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
  <path d="M128,20.00012a108,108,0,1,0,108,108A108.12217,108.12217,0,0,0,128,20.00012Zm0,192a84,84,0,1,1,84-84A84.0953,84.0953,0,0,1,128,212.00012Zm40.48535-107.51465L144.9707,128.00012l23.51465,23.51465a12.0001,12.0001,0,0,1-16.9707,16.9707L128,144.97082l-23.51465,23.51465a12.0001,12.0001,0,0,1-16.9707-16.9707l23.51465-23.51465L87.51465,104.48547a12.0001,12.0001,0,0,1,16.9707-16.9707L128,111.02942l23.51465-23.51465a12.0001,12.0001,0,0,1,16.9707,16.9707Z"/>
</svg></base-button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>SÀN</th>
              <th>Biệt danh</th>
              <th>Số tiền</th>
              <th>Loại</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in paginatedData" :key="index">
              <td>{{ data.areaName }}</td>
              <td>{{ data.nickName }}</td>
              <td>${{ data.amountBet }}</td>
              <td v-if="data.betType === 'BUY'" class="green">
                <svg
                  width="20"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                >
                  <path
                    style="fill: #a5eb78"
                    d="M512,256.001C512,114.615,397.385,0,255.999,0S0,114.615,0,256.001
	C0,397.385,114.615,512,255.999,512S512,397.385,512,256.001z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M158.815,392.039l93.111-48.308c2.554-1.325,5.594-1.325,8.148,0l93.111,48.308
	c7.261,3.767,15.306-3.494,12.302-11.102L264.227,124.533c-2.947-7.462-13.508-7.462-16.456,0L146.513,380.937
	C143.509,388.545,151.555,395.806,158.815,392.039z"
                  />
                  <path
                    style="opacity: 0.1; enable-background: new"
                    d="M68.872,256.001c0-129.706,96.466-236.866,221.564-253.688
	C279.172,0.798,267.68,0,256.001,0C114.615,0,0,114.615,0,256.001S114.615,512,256.001,512c11.68,0,23.171-0.798,34.436-2.313
	C165.338,492.865,68.872,385.705,68.872,256.001z"
                  />
                </svg>
              </td>
              <td v-else-if="data.betType === 'SELL'" class="red">
                <svg
                  width="20"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                  transform="scale(1, -1) translate(0, -512)"
                >
                  <path
                    style="fill: #ff0000"
                    d="M512,256.001C512,114.615,397.385,0,255.999,0S0,114.615,0,256.001
	C0,397.385,114.615,512,255.999,512S512,397.385,512,256.001z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M158.815,392.039l93.111-48.308c2.554-1.325,5.594-1.325,8.148,0l93.111,48.308
	c7.261,3.767,15.306-3.494,12.302-11.102L264.227,124.533c-2.947-7.462-13.508-7.462-16.456,0L146.513,380.937
	C143.509,388.545,151.555,395.806,158.815,392.039z"
                  />
                  <path
                    style="opacity: 0.1; enable-background: new"
                    d="M68.872,256.001c0-129.706,96.466-236.866,221.564-253.688
	C279.172,0.798,267.68,0,256.001,0C114.615,0,0,114.615,0,256.001S114.615,512,256.001,512c11.68,0,23.171-0.798,34.436-2.313
	C165.338,492.865,68.872,385.705,68.872,256.001z"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
        <vs-pagination
          v-model="page"
          :length="pageCount"
          @input="updateData"
        ></vs-pagination>
      </div>
    </card>
    <card>
      <div class="">
        <div class="col-md-8">
          <base-select
            :options="optionsPer"
            v-model="dataUser.differenceBreakBets"
            label="Bẻ cầu chênh lệnh"
            >Bẻ cầu chênh lệch
          </base-select>
        </div>
        <div class="col-md-8">
          <base-input
            label="Số tiền chênh lệnh"
            v-model="dataUser.differenceOver"
            name="item-differenceOver"
          >
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input
            label="Thời gian bẻ lệnh"
            v-model="dataUser.breakBetsStartSecond"
            name="item-breakBetsStartSecond"
          >
          </base-input>
        </div>
        <div class="col-md-8">
          <base-select
            :readonly="true"
            :options="optionsPer"
            v-model="dataUser.priceFundOnOff"
            label="Bẻ cầu theo quỹ"
            >Bẻ cầu theo quỹ</base-select
          >
        </div>
        <div class="col-md-8">
          <base-input
            label="Số tiền của quỹ"
            v-model="dataUser.priceFundProfits"
            name="item-priceFundProfits"
          >
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input
            label="Giới hận âm của quỹ"
            v-model="dataUser.priceStopLoss"
            name="item-priceStopLoss"
          >
          </base-input>
        </div>
        <div class="col-md-8">
          <base-select
            :readonly="true"
            :options="optionsPer"
            v-model="dataUser.autoPrice"
            label="Auto"
            >Auto</base-select
          >
        </div>
        <div class="col-md-8">
          <base-input
            label="Giá thấp nhất"
            v-model="dataUser.localChangeLow"
            name="item-priceStopLoss"
          >
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input
            label="Giá cao nhất"
            v-model="dataUser.localChangeHigh"
            name="item-priceStopLoss"
          >
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input
            label="Giá thấp nhất ( Kết quả )"
            v-model="dataUser.localResultLow"
            name="item-priceStopLoss"
          >
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input
            label="Giá cao nhất ( Kết quả )"
            v-model="dataUser.localResultHigh"
            name="item-priceStopLoss"
          >
          </base-input>
        </div>
      </div>
      <base-button @click="getBreakBetsConfig">Lấy dữ lệu</base-button>
      <base-button @click="updateBreakBetsConfig()">Lưu</base-button>
    </card>
    <card>
      <div class="row">
        <div class="col-12">
          <card title="Lịch sử giao dịch">
            <div class="col-md-5 pr-md-1">
              <base-select
                :options="optionsArea"
                label="Chọn sàn"
                v-model="searchInput.areaName"
                name="item-areaName"
              >
              </base-select>
            </div>
            <div class="col-md-5 pr-md-1">
              <base-input
                label="Biệt danh"
                v-model="searchInput.nickName"
                name="item-nickName"
              >
              </base-input>
            </div>
            <base-button @click="getListTrade()">Toàn Bộ</base-button>
            <base-button @click="getListTradeNick()">Tra cứu</base-button>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Sàn</th>
                    <th>Biệt danh</th>
                    <!-- <th>Account Type</th> -->
                    <th>Đặt cược</th>
                    <th>Tiền Thắng</th>
                    <th>Tiền Thua</th>

                    <th>Loại cược</th>
                    <th>Bẻ cầu</th>
                    <th>Kết quả</th>
                    <th>Kết quả cược</th>
                    <th>Thời gian</th>
                    <th>Tình trạng</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in paginatedData" :key="index">
                    <td>{{ data.areaName }}</td>
                    <td>{{ data.nickName }}</td>
                    <!-- <td>{{ data.accountType }}</td> -->
                    <td>${{ data.amountBet.toFixed(2) }}</td>
                    <td>${{ data.amountWin.toFixed(2) }}</td>
                    <td>${{ data.amountLose.toFixed(2) }}</td>

                    <td v-if="data.betType == 'BUY'" class="green">MUA</td>
                    <td v-if="data.betType == 'SELL'" class="red">BÁN</td>

                    <td v-if="data.breakType == 'BUY'" class="green">MUA</td>
                    <td v-if="data.breakType == 'SELL'" class="red">BÁN</td>
                    <td v-if="data.breakType == 'NONE'" class="white">KHÔNG</td>

                    <td v-if="data.open < data.close" class="green">MUA</td>
                    <td v-if="data.open > data.close" class="red">BÁN</td>
                    <td v-if="data.open == data.close" class="white">HÒA</td>

                    <td v-if="data.amountWin" class="green">THẮNG</td>
                    <td v-if="data.amountLose" class="red">THUA</td>
                    <td
                      v-if="(data.amountWin == '0') & (data.amountLose == '0')"
                    >
                      HÒA
                    </td>

                    <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
                    <td v-if="data.status == '1'">Hoàn thành</td>
                    <td v-if="data.status == '0'">Đang xử lý</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <vs-pagination
              v-model="page"
              :length="pageCount"
              @input="updateData"
            ></vs-pagination>
          </card>
        </div>
      </div>
    </card>
    <card>
      <div class="col-12">
        <card title="Tra cứu lợi nhuận ">
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Biệt danh"
              v-model="adminInput.nickName"
              name="item-nickName"
            >
            </base-input>
          </div>

          <div class="col-md-5 pr-md-1">
            <base-input
              type="datetime-local"
              label="Từ lúc"
              v-model="adminInput.fromTime"
              name="item-fromTime"
              :value="defaultFromDate"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              type="datetime-local"
              label="Đếnlúc"
              v-model="adminInput.toTime"
              name="item-toTime"
              :value="defaultFromDate"
            >
            </base-input>
          </div>
          <base-button @click="getProfit()">Tra cứu</base-button>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Tổng lần đặt"
              v-model="dataProfit.bet"
              name="item-nickName"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Tổng thắng"
              v-model="dataProfit.win"
              name="item-nickName"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Tổng thua"
              v-model="dataProfit.lose"
              name="item-nickName"
            >
            </base-input>
          </div>
          <div class="col-md-5 pr-md-1">
            <base-input
              label="Tổng lợi nhuận"
              v-model="dataProfit.profit"
              name="item-nickName"
            >
            </base-input>
          </div>
        </card>
      </div>
    </card>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from "../services/AuthenticationService";
import { VueSelect, VueSelectItem } from "vue-select";
import BaseDropdown from "../../components/BaseDropdown.vue";
import BaseSelect from "../../components/BaseSelect.vue";
import socketioService from "../../socket/socketio.service";
const now = new Date();
const yesterday = new Date(now);
yesterday.setDate(now.getDate() - 1);
const monthYesterday = (yesterday.getMonth() + 1).toString().padStart(2, "0");
const dateYesterday = yesterday.getDate().toString().padStart(2, "0");
const fromTime = `${yesterday.getFullYear()}-${monthYesterday}-${dateYesterday}T07:00`;

const monthToday = (now.getMonth() + 1).toString().padStart(2, "0");
const dateToday = now.getDate().toString().padStart(2, "0");
const toTime = `${now.getFullYear()}-${monthToday}-${dateToday}T07:00`;
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BaseTable,
    "vs-select": VueSelect,
    "vs-select-item": VueSelectItem,
    "base-select": BaseSelect,
  },
  data() {
    return {
      datatrade: [],
      itemsPerPage: 10,
      page: 1,
      optionsArea: [{ value: "BTCE", label: "BTCE" }],
      searchInput: {
        areaName: "ALL",
        nickName: "",
      },
      areaName: "",
      dataInput: [],
      result: "",
      adminInput: {
        name: "",
        fromTime: fromTime,
        toTime: toTime,
      },
      dataProfit: [],
      adminInput: {
        areaName: "",
        nickName: "",
        amountBet: "",
        betType: "",
      },
      dataUser: {
        differenceBreakBets: 0,
        priceFundOnOff: 1,
      },
      optionsArea: [{ value: "BTCE", label: "BTCE" }],
      optionsPer: [
        { value: false, label: "OFF" },
        { value: true, label: "ON" },
      ],
      differenceOver: "",
      breakBetsStartSecond: "",
      priceFundProfits: "",
      priceStopLoss: "",
      updatedFields: {},
      differenceBreakBets: 0,
      priceFundOnOff: 1,
      autoPrice: 0,
      countDown: 0,
      volumeBuy: 0,
      volumeSell: 0,
      datanow: [],
      datamkt: [],
      itemsPerPage: 30,
      page: 1,
      breakType: "",
      isBet: true,
      turnPhase: "",
      betAmount: 0,
    };
  },
  methods: {
    getListTrade() {
      let obj = {
        areaName: this.searchInput.areaName,
      };
      AuthenticationService.getListTrade(obj)
      .then((resp) => {
        if (resp.data.success) {
          this.datatrade = resp.data.data;
          console.log(this.datatrade);

          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "#4B0082",
          });
        }
        if (
          resp.data.success === false &&
          resp.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/goldentplus").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        }
      });
    },
    getListTradeNick() {
      let obj = {
        areaName: this.searchInput.areaName,
        nickName: this.searchInput.nickName,
      };
      AuthenticationService.getListTradeNick(obj).then((resp) => {
        if (resp.data.success) {
          this.datatrade = resp.data.data;
          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "#4B0082",
          });
        }
        if (
          resp.data.success === false &&
          resp.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/goldentplus").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        }
      });
    },
    updateData(page) {
      this.page = page;
    },
    getProfit() {
      let obj = {
        nickName: this.adminInput.nickName,
        fromTime: this.adminInput.fromTime,
        toTime: this.adminInput.toTime,
      };

      AuthenticationService.checkProfit(obj)
        .then((resp) => {
          if (resp.data.success) {
            this.dataProfit = resp.data.data;
            return this.$vs.notification({
              text: "Đã cập nhập thông tin thành công ",
              color: "#4B0082",
            });
          } else if (
            resp.data.success === false &&
            resp.data.errorType == "invalidAccessToken"
          ) {
            this.$router.push("/goldentplus").catch(() => {});
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: "Phiên đăng nhập đã hết hạn! ",
              color: "#4B0082",
            });
          } else if (resp.data.errorType == "Usermarketing") {
            return this.$vs.notification({
              text: "Biệt danh không hợp lệ! ",
              color: "#4B0082",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$vs.notification({
            text: "Biệt danh không hợp lệ!",
            color: "#4B0082",
          });
        });
    },
    getBreakBetsConfig() {
      AuthenticationService.getBreakBetsConfig().then((res) => {
        if (res.data.success) {
          this.dataUser = res.data.data;
          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "success",
            iconPack: "feather",
          });
        }
        if (
          res.data.success === false &&
          res.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/altutit").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        }
        if (res.data.success == false && res.data.errorType == "") {
          return this.$vs.notification({
            text: "Không thể tìm kiếm thông tin !",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      });
    },
    updateBreakBetsConfig() {
      const obj = {
        differenceBreakBets: this.dataUser.differenceBreakBets,
        differenceOver: parseFloat(this.dataUser.differenceOver),
        breakBetsStartSecond: parseFloat(this.dataUser.breakBetsStartSecond),
        priceFundOnOff: this.dataUser.priceFundOnOff,
        priceFundProfits: parseFloat(this.dataUser.priceFundProfits),
        priceStopLoss: parseFloat(this.dataUser.priceStopLoss),
        localChangeLow: parseFloat(this.dataUser.localChangeLow),
        localChangeHigh: parseFloat(this.dataUser.localChangeHigh),
        localResultLow: parseFloat(this.dataUser.localResultLow),
        localResultHigh: parseFloat(this.dataUser.localResultHigh),
        autoPrice: this.dataUser.autoPrice,
      };
      AuthenticationService.updateBreakBetsConfig(obj).then((resp) => {
        if (resp.data.success) {
          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "success",
            iconPack: "feather",
          });
        }
        if (
          res.data.success === false &&
          res.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/altutit").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        } else {
          return this.$vs.notification({
            text: "Không thể cập nhật thông tin !",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      });
    },

    searchUserEdit() {
      let obj = {
        areaName: this.adminInput.areaName,
        nickName: this.adminInput.nickName,
      };
      AuthenticationService.searchBetAdmin(obj).then((res) => {
        if (res.data.success) this.adminInput.balance = res.data.data;
      });
    },
    disabledBet() {
      return (this.isBet = !this.isBet ? true : false);
    },
    updateData(page) {
      this.page = page;
    },
    BetBuySell(betType) {
      let amountBet = this.betAmount.toString();
      amountBet = amountBet.replace(/[,]/g, "");
      amountBet = Number(amountBet);
      let accountType = "LIVE";
      betType = betType?.toUpperCase();
      let nickName = this.adminInput.nickName.toLocaleLowerCase();
      let areaName = this.adminInput.areaName;
      AuthenticationService.betAdmin({
        amountBet,
        accountType,
        betType,
        nickName,
        areaName,
      }).then((response) => {
        if (response.data.success) {
          this.searchUserEdit();
          return this.$vs.notification({
            text: "Đặt lệnh thành công",
            color: "var(--primary-color)",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
            time: 2000,
          });
        }
        if (
          response.data.success == false &&
          errorType == "insufficientBalance"
        ) {
          this.getUserInfo();
          return this.$vs.notification({
            text: "Số dư của bạn không đủ",
            color: "var(--primary-color)",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
            time: 2000,
          });
        }
      });
    },
    SelectBetType(type) {
      socketioService.socket.emit("breakBetType", type);
    },
  },
  computed: {
    defaultFromDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const time = "07:00 AM";
      return `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}T${time}`;
    },
    pageCount() {
      return Math.ceil(this.datanow.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datanow.slice(start, end);
    },
    isBetComputed() {
      return this.isBet;
    },
  },

  created() {
    socketioService.setupSocketConnection();
    socketioService.socket.emit("ADMIN_VOLUME_MONITOR_SUBCRIBE", {});
    socketioService.socket.on("ADMIN_LISTBET_MONITOR", (data) => {
      this.datanow = data.listBet
        .filter((a) => !a.marketing && a.accountType == "LIVE")
        .sort((a, b) => {
          if (a.betType < b.betType) {
            return -1;
          }
          if (a.betType > b.betType) {
            return 1;
          }
          return 0;
        });
      const amountBetByType = this.datanow.reduce(
        (acc, cur) => {
          if (cur.betType === "BUY") {
            acc.buy += Number.parseFloat(cur.amountBet);
          } else if (cur.betType === "SELL") {
            acc.sell += Number.parseFloat(cur.amountBet);
          }
          return acc;
        },
        { buy: 0, sell: 0 }
      );
    });

    socketioService.socket.on("ADMIN_VOLUME_MONITOR", (data) => {
      this.countDown = data.countdown + 1;
      this.volumeBuy = Number.parseFloat(data.volumeBuy);
      this.volumeSell = Number.parseFloat(data.volumeSell);
      this.breakType = data.breakType;
      this.session = Number(data.session);
      if (this.session % 2) {
        this.isBet = true;
        this.turnPhase = "ORDER";
      } else {
        this.isBet = false;
        this.turnPhase = "WAITING";
      }
    });

    socketioService.socket.on("breakBetType", (data) => {
      if (data.success) {
        return this.$vs.notification({
          text: "Điều chỉnh thành công !",
          color: "success",
        });
      } else {
        return this.$vs.notification({
          text: "Không thể điều chỉnh !",
          color: "success",
        });
      }
    });
  },
  mounted() {
    const clickDelay = 300;
    let lastClickTime = 0;
    document.addEventListener(
      "click",
      function (event) {
        const now = new Date().getTime();
        const timeSinceLastClick = now - lastClickTime;
        if (timeSinceLastClick <= clickDelay) {
          event.preventDefault();
        }
        lastClickTime = now;
      },
      false
    );
  },
};
</script>
<style>
.btn {
  padding: 11px 22.85px !important;
}

.green {
  color: green;
}

.red {
  color: red;
}

.timeCount {
  display: flex;
}

.form-control {
  margin-left: 2px;
  background: #1d253b;
}
.form-group {
  margin-left: 2px;
}
</style>
