<template>
  <div class="row">
    <div class="col-12">
      <card title="Lịch sử giao dịch ">
        <div class="col-md-5 pr-md-1">
          <base-select
            :options="optionsArea"
            label="Chọn sàn"
            v-model="searchInput.areaName"
            name="item-areaName"
          >
          </base-select>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-select
            :options="optionTypeAcc"
            label="Chọn loại tài khoản"
            v-model="searchInput.typeAcc"
            name="item-type"
          >
          </base-select>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Biệt danh"
            v-model="searchInput.nickName"
            name="item-nickName"
          >
          </base-input>
        </div>
        <base-button @click="getListTrade()">Toàn bộ</base-button>
        <base-button @click="getListTradeNick()">Tra cưu</base-button>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Sàn</th>
                <th>Biệt danh</th>
                <!-- <th>Account Type</th> -->
                <th>Số tiền</th>
                <th>Thắng cược</th>
                <th>Thua cược</th>

                <th>Loại cược</th>
                <th>Bẻ cầu</th>
                <th>Kết quả cầu</th>
                <th>Kết quả cược</th>
                <th>Thời gian</th>
                <th>Tình trạng</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in paginatedData" :key="index">
                <td>{{ data.areaName }}</td>
                <td>{{ data.nickName }}</td>
                <!-- <td>{{ data.accountType }}</td> -->
                <td>${{ data.amountBet.toFixed(2) }}</td>
                <td>${{ data.amountWin.toFixed(2) }}</td>
                <td>${{ data.amountLose.toFixed(2) }}</td>

                <td v-if="data.betType == 'BUY'" class="green"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#4ab548" transform="scale(1, -1) translate(0, -48)">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg>
</td>
                <td v-if="data.betType == 'SELL'" class="red"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#ff0800">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg></td>

                <td v-if="data.breakType == 'BUY'" class="green"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#4ab548" transform="scale(1, -1) translate(0, -48)">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg>
</td>
                <td v-if="data.breakType == 'SELL'" class="red"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#ff0800">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg></td>
                <td v-if="data.breakType == 'NONE'" class="white"><svg width="20px" height="20px" viewBox="0 0 24 24" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/">
 <g transform="translate(0 -1028.4)">
  <path d="m24 12c0 6.627-5.373 12-12 12-6.6274 0-12-5.373-12-12 0-6.6274 5.3726-12 12-12 6.627 0 12 5.3726 12 12z" transform="matrix(.91667 0 0 .91667 1 1030.4)" fill="#f39c12"/>
  <path d="m24 12c0 6.627-5.373 12-12 12-6.6274 0-12-5.373-12-12 0-6.6274 5.3726-12 12-12 6.627 0 12 5.3726 12 12z" transform="matrix(.83333 0 0 .83333 2 1030.4)" fill="#f39c12"/>
  <path d="m10 1032.4v2h-2-1v2h1v7h-1v2h1 2v2h1v-2h1 1v2h1v-2h0.5c1.933 0 3.5-1.4 3.5-3 0-1.3-0.78-2.6-2-3 0.647-0.6 1-1.1 1-2 0-1.7-1.343-3-3-3v-2h-1v2h-1-1v-2h-1zm0 4h2 2c0.552 0 1 0.4 1 1 0 0.5-0.448 1-1 1h-2-2v-2zm0 4h2 2c1.105 0 2 0.6 2 1.5 0 0.8-0.895 1.5-2 1.5h-2-2v-3z" fill="#f1c40f"/>
  <path d="m12 1029.4c-6.0751 0-11 4.9-11 11 0 6 4.9249 11 11 11 6.075 0 11-5 11-11 0-6.1-4.925-11-11-11zm0 2c4.971 0 9 4 9 9 0 4.9-4.029 9-9 9-4.9706 0-9-4.1-9-9 0-5 4.0294-9 9-9z" fill="#f1c40f"/>
  <path d="m7 1035.4v1h1v-1h-1zm3 0v1h2 2 0.125c0.478 0.1 0.859 0.4 0.875 0.9 0.022-0.3 0-0.8 0-0.9 0-0.6-0.448-1-1-1h-2-2zm0 4v1h2 2 0.312c0.917 0.1 1.631 0.7 1.688 1.4 0.028-0.3 0-0.8 0-0.9 0-0.9-0.895-1.5-2-1.5h-2-2zm7.938 2.4c-0.269 1.5-1.696 2.6-3.438 2.6h-0.5v1h0.5c0.242 0 0.491-0.1 0.719-0.1 1.595-0.3 2.781-1.5 2.781-2.9 0-0.2-0.031-0.4-0.062-0.6zm-10.938 2.6v1h1 2v-1h-2-1zm4 0v1h1 1v-1h-1-1zm-1 2v1h1v-1h-1zm3 0v1h1v-1h-1z" fill="#e67e22"/>
 </g>
</svg></td>

                <td v-if="data.open < data.close" class="green"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#4ab548" transform="scale(1, -1) translate(0, -48)">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg>
</td>
                <td v-if="data.open > data.close" class="red"><svg width="20px" height="20px" viewBox="0 0 48 48" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
    <g fill="#ff0800">
        <polygon points="24,44 12.3,30 35.7,30"/>
        <rect x="20" y="6" width="8" height="27"/>
    </g>
</svg></td>
                <td v-if="data.open == data.close" class="white"><svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill="#fff" fill-rule="evenodd" d="M3,2 L11,2 C12.3062521,2 13.4175144,2.8348501 13.8293257,4.00008893 L19,4 C20.5976809,4 21.9036609,5.24891996 21.9949073,6.82372721 L22,7 L22,15 C22,16.5976809 20.75108,17.9036609 19.1762728,17.9949073 L19,18 L15,18 C13.6941178,18 12.5831148,17.1656226 12.1710242,16.0009007 L4,16 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M14,6 L14,15 C14,15.5128358 14.3860402,15.9355072 14.8833789,15.9932723 L15,16 L19,16 C19.5128358,16 19.9355072,15.6139598 19.9932723,15.1166211 L20,15 L20,7 C20,6.48716416 19.6139598,6.06449284 19.1166211,6.00672773 L19,6 L14,6 Z M11,4 L4,4 L4,14 L12,14 L12,5 C12,4.48716416 11.6139598,4.06449284 11.1166211,4.00672773 L11,4 Z"/>
</svg></td>

                <td v-if="data.amountWin" class="green">
                  <svg
                    width="20px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 488 488"
                    style="enable-background: new 0 0 488 488"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #7eaa84"
                      d="M476.8,127.6H11.2c-6.4,0-11.2,4.8-11.2,11.2v216c0,6.4,4.8,11.2,11.2,11.2H476
	c6.4,0,11.2-4.8,11.2-11.2v-216C488,132.4,483.2,127.6,476.8,127.6z"
                    />
                    <path
                      style="fill: #b1ddb7"
                      d="M476.8,122H11.2C4.8,122,0,126.8,0,133.2v216c0,6.4,4.8,11.2,11.2,11.2H476c6.4,0,11.2-4.8,11.2-11.2
	v-216C488,126.8,483.2,122,476.8,122z"
                    />
                    <path
                      style="fill: #95c19d"
                      d="M476.8,361.2c6.4,0,11.2-4.8,11.2-11.2V134c0-6.4-4.8-11.2-11.2-11.2H11.2C4.8,122.8,0,127.6,0,134"
                    />
                    <path
                      style="fill: #52a85a"
                      d="M464,186.8c-21.6,0-39.2-17.6-39.2-39.2c0-1.6,0-3.2,0.8-4.8H63.2c0,1.6,0.8,3.2,0.8,4.8
	c0,21.6-17.6,39.2-39.2,39.2c-1.6,0-3.2,0-4.8-0.8v110.4c1.6,0,3.2-0.8,4.8-0.8c21.6,0,39.2,17.6,39.2,39.2c0,1.6,0,3.2-0.8,4H424
	c0-1.6-0.8-3.2-0.8-4c0-21.6,17.6-39.2,39.2-39.2c1.6,0,3.2,0,4.8,0.8V186C466.4,186.8,465.6,186.8,464,186.8z"
                    />
                    <path
                      style="fill: #4d9356"
                      d="M424,146.8c0-1.6,0-3.2,0.8-4.8H63.2c0,1.6,0.8,3.2,0.8,4.8c0,5.6-0.8,10.4-3.2,15.2L424,335.6l0,0
	c0-21.6,17.6-39.2,39.2-39.2c1.6,0,3.2,0,4.8,0.8V186c-1.6,0-3.2,0.8-4.8,0.8C441.6,186.8,424,169.2,424,146.8z"
                    />
                    <g>
                      <path
                        style="fill: #52a85a"
                        d="M22.4,169.2c-1.6,0-3.2-1.6-3.2-3.2s1.6-3.2,3.2-3.2c10.4,0,19.2-8.8,19.2-19.2
		c0-1.6,1.6-3.2,3.2-3.2c1.6,0,3.2,1.6,3.2,3.2C47.2,158.8,36.8,169.2,22.4,169.2z"
                      />
                      <path
                        style="fill: #52a85a"
                        d="M47.2,338c0,1.6-1.6,3.2-3.2,3.2c-1.6,0-3.2-1.6-3.2-3.2c0-10.4-8.8-19.2-19.2-19.2
		c0,0.8-1.6-0.8-1.6-2.4s1.6-3.2,3.2-3.2C36.8,313.2,47.2,324.4,47.2,338z"
                      />
                      <path
                        style="fill: #52a85a"
                        d="M465.6,313.2c1.6,0,3.2,1.6,3.2,3.2s-1.6,3.2-3.2,3.2c-10.4,0-19.2,8.8-19.2,19.2
		c0,1.6-1.6,3.2-3.2,3.2s-3.2-1.6-3.2-3.2C440.8,324.4,451.2,313.2,465.6,313.2z"
                      />
                      <path
                        style="fill: #52a85a"
                        d="M440.8,145.2c0-1.6,1.6-3.2,3.2-3.2s3.2,1.6,3.2,3.2c0,10.4,8.8,19.2,19.2,19.2
		c1.6,0,3.2,1.6,3.2,3.2s-1.6,3.2-3.2,3.2C451.2,169.2,440.8,158.8,440.8,145.2z"
                      />
                    </g>
                    <circle
                      style="fill: #7eaa84"
                      cx="244"
                      cy="241.2"
                      r="78.4"
                    />
                    <path
                      style="fill: #49754c"
                      d="M244,320.4c34.4,0,64-22.4,74.4-53.6l-25.6-25.6c-5.6-5.6-16-5.6-21.6,0l-5.6,5.6l-30.4-30.4
	c-5.6-5.6-16-5.6-21.6,0L168,262C177.6,295.6,208,320.4,244,320.4z"
                    />
                    <g>
                      <path
                        style="fill: #5e8e63"
                        d="M281.6,252.4c5.6-5.6,15.2-5.6,21.6,0L292.8,242c-5.6-5.6-16-5.6-21.6,0l-5.6,5.6L276,258
		L281.6,252.4z"
                      />
                      <path
                        style="fill: #5e8e63"
                        d="M178.4,273.2l45.6-45.6c5.6-5.6,16-5.6,21.6,0l-10.4-10.4c-5.6-5.6-16-5.6-21.6,0L168,262.8
		c4,16,13.6,29.6,25.6,39.2C186.4,294,181.6,283.6,178.4,273.2z"
                      />
                    </g>
                    <path
                      style="fill: #99d1a0"
                      d="M244,327.6c-48,0-86.4-38.4-86.4-86.4s38.4-86.4,86.4-86.4s86.4,38.4,86.4,86.4S292,327.6,244,327.6z
	 M244,170c-39.2,0-71.2,32-71.2,71.2s32,71.2,71.2,71.2s71.2-32,71.2-71.2S283.2,170,244,170z"
                    />
                    <path
                      style="fill: #95c19d"
                      d="M318.4,286c8-12.8,12-28,12-44c0-48-38.4-86.4-86.4-86.4c-36.8,0-68.8,23.2-80.8,56l13.6,6.4
	c9.6-28,36-48,67.2-48c39.2,0,71.2,32,71.2,71.2c0,13.6-4,26.4-10.4,37.6L318.4,286z"
                    />
                    <g>
                      <path
                        style="fill: #b1ddb7"
                        d="M139.2,241.2c0,1.6-0.8,2.4-2.4,2.4H41.6c-1.6,0-2.4-0.8-2.4-2.4l0,0c0-1.6,0.8-2.4,2.4-2.4h95.2
		C138.4,239.6,139.2,240.4,139.2,241.2L139.2,241.2z"
                      />
                      <circle
                        style="fill: #b1ddb7"
                        cx="29.6"
                        cy="241.2"
                        r="2.4"
                      />
                      <path
                        style="fill: #b1ddb7"
                        d="M348.8,241.2c0-1.6,0.8-2.4,2.4-2.4h95.2c1.6,0,2.4,0.8,2.4,2.4l0,0c0,1.6-0.8,2.4-2.4,2.4h-95.2
		C349.6,243.6,348.8,242.8,348.8,241.2L348.8,241.2z"
                      />
                      <circle
                        style="fill: #b1ddb7"
                        cx="458.4"
                        cy="241.2"
                        r="2.4"
                      />
                    </g>
                    <path
                      style="fill: #95c19d"
                      d="M262.4,202.8c-7.2,0-13.6-6.4-13.6-13.6c0-1.6,0-3.2,0.8-4.8c-4.8,1.6-8.8,7.2-8.8,12.8
	c0,7.2,6.4,13.6,13.6,13.6c5.6,0,10.4-3.2,12.8-8.8C266.4,202.8,264.8,202.8,262.4,202.8z"
                    />
                    <g>
                      <circle
                        style="fill: #4d9356"
                        cx="52.8"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="65.6"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="78.4"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="91.2"
                        cy="199.6"
                        r="2.4"
                      />
                      <path
                        style="fill: #4d9356"
                        d="M106.4,199.6c0,1.6-0.8,2.4-2.4,2.4s-2.4-0.8-2.4-2.4s0.8-2.4,2.4-2.4S106.4,198.8,106.4,199.6z"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="116"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="128.8"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="141.6"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="52.8"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="65.6"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="78.4"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="91.2"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="103.2"
                        cy="214"
                        r="2.4"
                      />
                      <circle style="fill: #4d9356" cx="116" cy="214" r="2.4" />
                      <circle
                        style="fill: #4d9356"
                        cx="128.8"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="141.6"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="52.8"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="65.6"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="78.4"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="91.2"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="103.2"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="116"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="128.8"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="141.6"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="52.8"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="65.6"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="78.4"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="91.2"
                        cy="254.8"
                        r="2.4"
                      />
                      <path
                        style="fill: #4d9356"
                        d="M106.4,254.8c0,1.6-0.8,2.4-2.4,2.4s-2.4-0.8-2.4-2.4s0.8-2.4,2.4-2.4S106.4,254,106.4,254.8z"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="116"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="128.8"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="141.6"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="52.8"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="65.6"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="78.4"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="91.2"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="103.2"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="116"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="128.8"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="141.6"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="52.8"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="65.6"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="78.4"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="91.2"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="103.2"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="116"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="128.8"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="141.6"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="346.4"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="359.2"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="372"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="384.8"
                        cy="199.6"
                        r="2.4"
                      />
                      <path
                        style="fill: #4d9356"
                        d="M400,199.6c0,1.6-0.8,2.4-2.4,2.4s-2.4-0.8-2.4-2.4s0.8-2.4,2.4-2.4
		C398.4,197.2,400,198.8,400,199.6z"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="409.6"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="422.4"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="435.2"
                        cy="199.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="346.4"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="359.2"
                        cy="214"
                        r="2.4"
                      />
                      <circle style="fill: #4d9356" cx="372" cy="214" r="2.4" />
                      <circle
                        style="fill: #4d9356"
                        cx="384.8"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="396.8"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="409.6"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="422.4"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="435.2"
                        cy="214"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="346.4"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="359.2"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="372"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="384.8"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="396.8"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="409.6"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="422.4"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="435.2"
                        cy="227.6"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="346.4"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="359.2"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="372"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="384.8"
                        cy="254.8"
                        r="2.4"
                      />
                      <path
                        style="fill: #4d9356"
                        d="M400,254.8c0,1.6-0.8,2.4-2.4,2.4s-2.4-0.8-2.4-2.4s0.8-2.4,1.6-2.4C398.4,252.4,400,254,400,254.8z
		"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="409.6"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="422.4"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="435.2"
                        cy="254.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="346.4"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="359.2"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="372"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="384.8"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="396.8"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="409.6"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="422.4"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="435.2"
                        cy="269.2"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="346.4"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="359.2"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="372"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="384.8"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="396.8"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="409.6"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="422.4"
                        cy="282.8"
                        r="2.4"
                      />
                      <circle
                        style="fill: #4d9356"
                        cx="435.2"
                        cy="282.8"
                        r="2.4"
                      />
                    </g>
              </svg>
                </td>
                <td v-if="data.amountLose" class="red"><svg width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 496.8 496.8" style="enable-background:new 0 0 496.8 496.8;" xml:space="preserve">
<path style="fill:#D65C88;" d="M484,129.2H12c-6.4,0-12,5.6-12,12v220c0,6.4,5.6,12,12,12h472.8c6.4,0,12-5.6,12-12v-220
	C496,134.8,490.4,129.2,484,129.2z"/>
<path style="fill:#EF7BA1;" d="M484,123.6H12c-6.4,0-12,5.6-12,12v220c0,6.4,5.6,12,12,12h472.8c6.4,0,12-5.6,12-12v-220
	C496,129.2,490.4,123.6,484,123.6z"/>
<path style="fill:#DD5F8C;" d="M484,367.6c6.4,0,12-5.6,12-12v-220c0-6.4-5.6-12-12-12H12c-6.4,0-12,5.6-12,12"/>
<path style="fill:#BC224A;" d="M471.2,190c-22.4,0-40-17.6-40-40c0-1.6,0-3.2,0.8-4.8H64.8c0,1.6,0.8,3.2,0.8,4.8
	c0,22.4-18.4,40-40,40c-1.6,0-3.2,0-4.8-0.8v112c1.6,0,3.2-0.8,4.8-0.8c22.4,0,40,17.6,40,40c0,1.6,0,3.2-0.8,4.8H432
	c0-1.6-0.8-3.2-0.8-4.8c0-22.4,17.6-40,40-40c1.6,0,3.2,0,4.8,0.8v-112C474.4,189.2,472.8,190,471.2,190z"/>
<path style="fill:#A51642;" d="M431.2,149.2c0-1.6,0-3.2,0.8-4.8H64.8c0,1.6,0.8,3.2,0.8,4.8c0,5.6-0.8,10.4-3.2,15.2L432,341.2l0,0
	c0-22.4,17.6-40,40-40c1.6,0,3.2,0,4.8,0.8V190c-1.6,0-3.2,0.8-4.8,0.8C448.8,190,431.2,171.6,431.2,149.2z"/>
<g>
	<path style="fill:#BC224A;" d="M23.2,172.4c-1.6,0-3.2-1.6-3.2-3.2c0-1.6,1.6-3.2,3.2-3.2c10.4,0,19.2-8.8,19.2-19.2
		c0-1.6,1.6-3.2,3.2-3.2c1.6,0.8,2.4,1.6,2.4,3.2C48,161.2,36.8,172.4,23.2,172.4z"/>
	<path style="fill:#BC224A;" d="M48,343.6c0,1.6-1.6,3.2-3.2,3.2c-1.6,0-3.2-1.6-3.2-3.2c0-10.4-8.8-19.2-19.2-19.2
		c-1.6,0-3.2-1.6-3.2-3.2s1.6-3.2,3.2-3.2C36.8,318.8,48,330,48,343.6z"/>
	<path style="fill:#BC224A;" d="M472.8,318.8c1.6,0,3.2,1.6,3.2,3.2s-1.6,3.2-3.2,3.2c-10.4,0-19.2,8.8-19.2,19.2
		c0,1.6-1.6,3.2-3.2,3.2s-3.2-1.6-3.2-3.2C448,330,459.2,318.8,472.8,318.8z"/>
	<path style="fill:#BC224A;" d="M448,146.8c0-1.6,1.6-3.2,3.2-3.2s3.2,1.6,3.2,3.2c0,10.4,8.8,19.2,19.2,19.2c1.6,0,3.2,1.6,3.2,3.2
		c0,1.6-1.6,3.2-3.2,3.2C459.2,172.4,448,161.2,448,146.8z"/>
</g>
<circle style="fill:#FC3875;" cx="248" cy="245.2" r="80"/>
<g>
	<ellipse style="fill:#A51642;" cx="248" cy="285.2" rx="62.4" ry="43.2"/>
	<path style="fill:#A51642;" d="M298.4,229.2c0,21.6-22.4,30.4-50.4,30.4s-50.4-8.8-50.4-30.4S220,190,248,190
		S298.4,206.8,298.4,229.2z"/>
</g>
<g>
	<circle style="fill:#FFF5F9;" cx="232" cy="222" r="4"/>
	<circle style="fill:#FFF5F9;" cx="264" cy="222" r="4"/>
</g>
<g>
	<circle style="fill:#A51642;" cx="216" cy="202.8" r="12.8"/>
	<circle style="fill:#A51642;" cx="280" cy="202.8" r="12.8"/>
</g>
<path style="fill:#EF7BA1;" d="M248,333.2c-48.8,0-88-39.2-88-88s39.2-88,88-88s88,39.2,88,88S296.8,333.2,248,333.2z M248,173.2
	c-40,0-72,32.8-72,72c0,40,32.8,72,72,72c40,0,72-32.8,72-72S288,173.2,248,173.2z"/>
<path style="fill:#DD5F8C;" d="M323.2,290c8-12.8,12.8-28.8,12.8-44.8c0-48.8-39.2-88-88-88c-37.6,0-69.6,24-82.4,57.6l14.4,6.4
	c9.6-28,36.8-48.8,68-48.8c40,0,72,32.8,72,72c0,13.6-4,27.2-11.2,38.4L323.2,290z"/>
<g>
	<path style="fill:#EF7BA1;" d="M141.6,245.2c0,1.6-0.8,2.4-2.4,2.4H42.4c-1.6,0-2.4-0.8-2.4-2.4l0,0c0-1.6,0.8-2.4,2.4-2.4h96.8
		C140.8,243.6,141.6,244.4,141.6,245.2L141.6,245.2z"/>
	<circle style="fill:#EF7BA1;" cx="29.6" cy="245.2" r="2.4"/>
	<path style="fill:#EF7BA1;" d="M354.4,245.2c0-1.6,0.8-2.4,2.4-2.4h96.8c1.6,0,2.4,0.8,2.4,2.4l0,0c0,1.6-0.8,2.4-2.4,2.4h-96.8
		C355.2,247.6,354.4,246.8,354.4,245.2L354.4,245.2z"/>
	<circle style="fill:#EF7BA1;" cx="466.4" cy="245.2" r="2.4"/>
</g>
<g>
	<circle style="fill:#A51642;" cx="53.6" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="66.4" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="79.2" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="92" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="105.6" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="118.4" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="131.2" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="144" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="53.6" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="66.4" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="79.2" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="92" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="105.6" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="118.4" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="131.2" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="144" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="53.6" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="66.4" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="79.2" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="92" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="105.6" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="118.4" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="131.2" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="144" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="53.6" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="66.4" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="79.2" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="92" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="105.6" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="118.4" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="131.2" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="144" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="53.6" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="66.4" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="79.2" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="92" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="105.6" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="118.4" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="131.2" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="144" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="53.6" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="66.4" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="79.2" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="92" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="105.6" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="118.4" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="131.2" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="144" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="352" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="364.8" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="377.6" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="390.4" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="404" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="416.8" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="429.6" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="442.4" cy="202.8" r="2.4"/>
	<circle style="fill:#A51642;" cx="352" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="364.8" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="377.6" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="390.4" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="404" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="416.8" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="429.6" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="442.4" cy="217.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="352" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="364.8" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="377.6" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="390.4" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="404" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="416.8" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="429.6" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="442.4" cy="231.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="352" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="364.8" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="377.6" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="390.4" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="404" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="416.8" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="429.6" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="442.4" cy="259.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="352" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="364.8" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="377.6" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="390.4" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="404" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="416.8" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="429.6" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="442.4" cy="273.2" r="2.4"/>
	<circle style="fill:#A51642;" cx="352" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="364.8" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="377.6" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="390.4" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="404" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="416.8" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="429.6" cy="287.6" r="2.4"/>
	<circle style="fill:#A51642;" cx="442.4" cy="287.6" r="2.4"/>
</g>
</svg></td>
                <td v-if="(data.amountWin == '0') & (data.amountLose == '0')">
                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill="#fff" fill-rule="evenodd" d="M3,2 L11,2 C12.3062521,2 13.4175144,2.8348501 13.8293257,4.00008893 L19,4 C20.5976809,4 21.9036609,5.24891996 21.9949073,6.82372721 L22,7 L22,15 C22,16.5976809 20.75108,17.9036609 19.1762728,17.9949073 L19,18 L15,18 C13.6941178,18 12.5831148,17.1656226 12.1710242,16.0009007 L4,16 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M14,6 L14,15 C14,15.5128358 14.3860402,15.9355072 14.8833789,15.9932723 L15,16 L19,16 C19.5128358,16 19.9355072,15.6139598 19.9932723,15.1166211 L20,15 L20,7 C20,6.48716416 19.6139598,6.06449284 19.1166211,6.00672773 L19,6 L14,6 Z M11,4 L4,4 L4,14 L12,14 L12,5 C12,4.48716416 11.6139598,4.06449284 11.1166211,4.00672773 L11,4 Z"/>
</svg>
                </td>

                <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
                <td v-if="data.status == '1'">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M511.891456 928.549888c229.548032 0 415.634432-186.0864 415.634432-415.634432C927.525888 283.3664 741.439488 97.28 511.890432 97.28 282.343424 97.28 96.258048 283.3664 96.258048 512.915456c0 229.548032 186.084352 415.634432 415.634432 415.634432"
                      fill="#41ba14"
                    />
                    <path
                      d="M436.571136 707.376128l330.3936-330.3936c5.506048-5.507072 8.571904-12.803072 8.633344-20.544512 0.060416-7.85408-2.961408-15.235072-8.511488-20.784128 0.001024-0.012288-0.001024-0.002048-0.001024-0.002048l-0.001024-0.001024c-5.410816-5.409792-12.978176-8.489984-20.687872-8.460288-7.810048 0.032768-15.13984 3.081216-20.640768 8.58112l-309.11488 309.116928-94.99648-94.998528c-5.501952-5.501952-12.833792-8.5504-20.642816-8.58112h-0.115712c-7.69536 0-15.186944 3.08224-20.569088 8.465408-11.360256 11.36128-11.307008 29.899776 0.118784 41.325568l109.924352 109.924352a29.017088 29.017088 0 0 0 4.883456 6.474752c5.658624 5.6576 13.095936 8.482816 20.550656 8.481792a29.31712 29.31712 0 0 0 20.77696-8.604672M511.891456 97.28C282.3424 97.28 96.256 283.3664 96.256 512.915456s186.0864 415.634432 415.635456 415.634432c229.548032 0 415.634432-186.085376 415.634432-415.634432C927.525888 283.365376 741.439488 97.28 511.891456 97.28m0 40.96c50.597888 0 99.661824 9.901056 145.82784 29.427712 44.61056 18.868224 84.683776 45.889536 119.10656 80.31232 34.422784 34.422784 61.444096 74.496 80.313344 119.107584 19.525632 46.164992 29.426688 95.228928 29.426688 145.82784s-9.901056 99.662848-29.426688 145.82784c-18.869248 44.61056-45.89056 84.6848-80.313344 119.107584s-74.496 61.443072-119.10656 80.31232c-46.166016 19.526656-95.229952 29.426688-145.82784 29.426688-50.598912 0-99.662848-9.900032-145.828864-29.426688-44.61056-18.869248-84.683776-45.889536-119.10656-80.31232-34.422784-34.422784-61.444096-74.497024-80.313344-119.107584C147.117056 612.57728 137.216 563.514368 137.216 512.915456s9.901056-99.662848 29.426688-145.82784c18.869248-44.611584 45.89056-84.6848 80.313344-119.107584s74.496-61.444096 119.10656-80.31232C412.228608 148.140032 461.292544 138.24 511.891456 138.24"
                      fill="#000000"
                    />
                  </svg>
                </td>
                <td v-if="data.status == '0'">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M511.891456 928.549888c229.548032 0 415.634432-186.0864 415.634432-415.634432C927.525888 283.3664 741.439488 97.28 511.890432 97.28 282.343424 97.28 96.258048 283.3664 96.258048 512.915456c0 229.548032 186.084352 415.634432 415.634432 415.634432"
                      fill="#ffdd00"
                    />
                    <path
                      d="M436.571136 707.376128l330.3936-330.3936c5.506048-5.507072 8.571904-12.803072 8.633344-20.544512 0.060416-7.85408-2.961408-15.235072-8.511488-20.784128 0.001024-0.012288-0.001024-0.002048-0.001024-0.002048l-0.001024-0.001024c-5.410816-5.409792-12.978176-8.489984-20.687872-8.460288-7.810048 0.032768-15.13984 3.081216-20.640768 8.58112l-309.11488 309.116928-94.99648-94.998528c-5.501952-5.501952-12.833792-8.5504-20.642816-8.58112h-0.115712c-7.69536 0-15.186944 3.08224-20.569088 8.465408-11.360256 11.36128-11.307008 29.899776 0.118784 41.325568l109.924352 109.924352a29.017088 29.017088 0 0 0 4.883456 6.474752c5.658624 5.6576 13.095936 8.482816 20.550656 8.481792a29.31712 29.31712 0 0 0 20.77696-8.604672M511.891456 97.28C282.3424 97.28 96.256 283.3664 96.256 512.915456s186.0864 415.634432 415.635456 415.634432c229.548032 0 415.634432-186.085376 415.634432-415.634432C927.525888 283.365376 741.439488 97.28 511.891456 97.28m0 40.96c50.597888 0 99.661824 9.901056 145.82784 29.427712 44.61056 18.868224 84.683776 45.889536 119.10656 80.31232 34.422784 34.422784 61.444096 74.496 80.313344 119.107584 19.525632 46.164992 29.426688 95.228928 29.426688 145.82784s-9.901056 99.662848-29.426688 145.82784c-18.869248 44.61056-45.89056 84.6848-80.313344 119.107584s-74.496 61.443072-119.10656 80.31232c-46.166016 19.526656-95.229952 29.426688-145.82784 29.426688-50.598912 0-99.662848-9.900032-145.828864-29.426688-44.61056-18.869248-84.683776-45.889536-119.10656-80.31232-34.422784-34.422784-61.444096-74.497024-80.313344-119.107584C147.117056 612.57728 137.216 563.514368 137.216 512.915456s9.901056-99.662848 29.426688-145.82784c18.869248-44.611584 45.89056-84.6848 80.313344-119.107584s74.496-61.444096 119.10656-80.31232C412.228608 148.140032 461.292544 138.24 511.891456 138.24"
                      fill="#000000"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <vs-pagination
          v-model="page"
          :length="pageCount"
          @input="updateData"
        ></vs-pagination>
      </card>
    </div>
    <div class="col-12">
      <card title="Kiểm tra lợi nhuận ">
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Biệt danh"
            v-model="adminInput.nickName"
            name="item-nickName"
          >
          </base-input>
        </div>

        <div class="col-md-5 pr-md-1">
          <base-input
            type="datetime-local"
            label="Từ lúc"
            v-model="adminInput.fromTime"
            name="item-fromTime"
            :value="defaultFromDate"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            type="datetime-local"
            label="Đếnlúc"
            v-model="adminInput.toTime"
            name="item-toTime"
            :value="defaultFromDate"
          >
          </base-input>
        </div>
        <base-button @click="getProfit()">Kiểm Tra</base-button>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Tổng cược"
            v-model="dataProfit.bet"
            name="item-nickName"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Tổng thắng"
            v-model="dataProfit.win"
            name="item-nickName"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Tổng thua"
            v-model="dataProfit.lose"
            name="item-nickName"
          >
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input
            label="Tổng lợi nhuận"
            v-model="dataProfit.profit"
            name="item-nickName"
          >
          </base-input>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from "./services/AuthenticationService";
const tableColumns = [
  "AreaName",
  "NickName",
  "From",
  "Amount",
  "Time",
  "Status",
];
import BaseSelect from "../components/BaseSelect";

export default {
  components: {
    BaseTable,
    "base-select": BaseSelect,
  },
  props: {},
  data() {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const monthYesterday = (yesterday.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dateYesterday = yesterday.getDate().toString().padStart(2, "0");
    const fromTime = `${yesterday.getFullYear()}-${monthYesterday}-${dateYesterday}T07:00`;

    const monthToday = (now.getMonth() + 1).toString().padStart(2, "0");
    const dateToday = now.getDate().toString().padStart(2, "0");
    const toTime = `${now.getFullYear()}-${monthToday}-${dateToday}T07:00`;
    return {
      datatrade: [],
      itemsPerPage: 10,
      page: 1,
      optionsArea: [{ value: "BTCE", label: "BTCE" }],
      searchInput: {
        areaName: "ALL",
        nickName: "",
        typeAcc: "ALL"
      },
      
      optionTypeAcc: [
        {value: "1", label: "Tất cả"},
        {value: "2", label: "Tài khoản thường"},
        {value: "3", label: "Tài khoản MKT"}
      ],
      areaName: "",
      dataInput: [],
      result: "",
      adminInput: {
        name: "",
        fromTime: fromTime,
        toTime: toTime,
      },
      dataProfit: [],
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.datatrade.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datatrade.slice(start, end);
    },
    defaultFromDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const time = "07:00 AM";
      return `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}T${time}`;
    },
  },
  methods: {
    getListTrade() {
      let obj = {
        areaName: this.searchInput.areaName,
        
        typeAcc: this.searchInput.typeAcc
      };
      console.log(obj);
      
      AuthenticationService.getListTrade(obj)
      .then((resp) => {
        if (resp.data.success) {
          this.datatrade = resp.data.data;
          console.log(this.datatrade);

          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "#4B0082",
          });
        }
        if (
          resp.data.success === false &&
          resp.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/goldentplus").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        }
      });
    },
    getListTradeNick() {
      let obj = {
        areaName: this.searchInput.areaName,
        nickName: this.searchInput.nickName,
        typeAcc: this.searchInput.typeAcc
      };
      AuthenticationService.getListTradeNick(obj).then((resp) => {
        if (resp.data.success) {
          this.datatrade = resp.data.data;
          return this.$vs.notification({
            text: "Đã cập nhập thông tin thành công ",
            color: "#4B0082",
          });
        }
        if (
          resp.data.success === false &&
          resp.data.errorType == "invalidAccessToken"
        ) {
          this.$router.push("/goldentplus").catch(() => {});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("INFO");
          return this.$vs.notification({
            text: "Phiên đăng nhập đã hết hạn! ",
            color: "#4B0082",
          });
        }
      });
    },
    updateData(page) {
      this.page = page;
    },
    getProfit() {
      let obj = {
        nickName: this.adminInput.nickName,
        fromTime: this.adminInput.fromTime,
        toTime: this.adminInput.toTime,
      };

      AuthenticationService.checkProfit(obj)
        .then((resp) => {
          if (resp.data.success) {
            this.dataProfit = resp.data.data;
            return this.$vs.notification({
              text: "Đã cập nhập thông tin thành công ",
              color: "#4B0082",
            });
          } else if (
            resp.data.success === false &&
            resp.data.errorType == "invalidAccessToken"
          ) {
            this.$router.push("/goldentplus").catch(() => {});
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: "Phiên đăng nhập đã hết hạn! ",
              color: "#4B0082",
            });
          } else if (resp.data.errorType == "Usermarketing") {
            return this.$vs.notification({
              text: "Biệt danh không hợp lệ! ",
              color: "#4B0082",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$vs.notification({
            text: "Biệt danh không hợp lệ!",
            color: "#4B0082",
          });
        });
    },
  },
};
</script>
<style>
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  color: #fff;
}

.table th {
  font-weight: bold;
  background-color: #00172e;
  border-bottom: 2px solid #dee2e6;
  color: #fff !important;
}

.table tbody tr:nth-child(even) {
  background-color: #070025;
}

.green {
  color: rgb(0, 255, 0) !important;
}

.red {
  color: red !important;
}
.white {
  color: white !important;
}
</style>
